@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: auto;
  height: 100vh;
  overflow: hidden;
  user-select: none;
}

code {
  font-family: "Inter", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
b {
  font-family: "Inter", sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  z-index: 1;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a5aaad;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  /* background: #3ea175; */
  background: #1876f3;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a5aaad;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
